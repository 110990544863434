/* @font-face {
  font-family: "Helvetica";
  src: url(./fonts/Helvetica-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica";
  src: url(./fonts/helvetica\400ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica-bold";
  src: url(./fonts/HelveticaBlack.otf) format("truetype");
  font-weight: 900;
  font-style: normal;
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  position: relative;
  background: #080808;
  color: #fff;
  font-family: "Foco Light", "Helvetica", sans-serif !important;
}

a {
  text-decoration: none;
}

.helvetica {
  font-family: "Foco Light", Helvetica, sans-serif;
}

.inter {
  font-family: "Inter", sans-serif;
}

.bold {
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
}

button {
  background-color: #ca2069;
  border: none;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  margin-top: 35px;
  padding: 15px 64px;
}

.pinkcolor {
  color: #ca2069;
}
.text_uppercase {
  text-transform: uppercase;
}

.radial_container {
  position: relative;
  z-index: 10;
}

.radial_circle {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 950px;
  background: radial-gradient(
    farthest-side at top left,
    rgba(0, 56, 255, 0.64) 0%,
    rgba(0, 56, 255, 0.3392) 10%,
    rgba(203, 0, 221, 0.12) 50%,
    rgba(203, 0, 221, 0) 75%
  );
  /* background: radial-gradient(rgba(0,56,255,0.3392) -10.4%,rgba(185,45,152,20%) 73.67%); */
  z-index: 1;
  /* border-radius: 50%; */
}

.radial_circle1 {
  position: absolute;
  top: -80%;
  left: 0;
  width: 100%;
  height: 1400px;
  background: radial-gradient(
    farthest-side at left,
    rgba(0, 56, 255, 0.64) 0%,
    rgba(0, 56, 255, 0.3392) 0%,
    rgba(203, 0, 221, 0.12) 50%,
    rgba(203, 0, 221, 0) 75%
  );
  /* background: radial-gradient(rgba(0,56,255,0.3392) -10.4%,rgba(185,45,152,20%) 73.67%); */
  z-index: 1;
  /* border-radius: 50%; */
}

.radial_circle2 {
  position: absolute;
  top: -31%;
  right: 0%;
  width: 100%;
  height: 1700px;
  background: radial-gradient(
    farthest-side at right,
    rgba(0, 56, 255, 0.64) 0%,
    rgba(0, 56, 255, 0.3392) 0%,
    rgba(203, 0, 221, 0.12) 50%,
    rgba(203, 0, 221, 0) 75%
  );
  /* background: radial-gradient(rgba(0,56,255,0.3392) -10.4%,rgba(185,45,152,20%) 73.67%); */
  z-index: 1;
  /* border-radius: 50%; */
}
.radial_circle3 {
  position: absolute;
  top: -44%;
  left: 0%;
  width: 100%;
  height: 1000px;
  background: radial-gradient(
    farthest-side at left,
    rgba(0, 56, 255, 0.23) 0%,
    rgba(0, 56, 255, 0.19) 0%,
    rgba(203, 0, 221, 0.07) 50%,
    rgba(203, 0, 221, 0) 75%
  );
  /* background: radial-gradient(rgba(0,56,255,0.3392) -10.4%,rgba(185,45,152,20%) 73.67%); */
  z-index: 1;
  /* border-radius: 50%; */
}
.radial_circle4 {
  position: absolute;
  top: 0%;
  right: 0%;
  width: 100%;
  height: 1000px;
  background: radial-gradient(
    farthest-side at right,
    rgba(0, 56, 255, 0.23) 0%,
    rgba(0, 56, 255, 0.19) 0%,
    rgba(203, 0, 221, 0.07) 50%,
    rgba(203, 0, 221, 0) 75%
  );
  /* background: radial-gradient(rgba(0,56,255,0.3392) -10.4%,rgba(185,45,152,20%) 73.67%); */
  z-index: 1;
  /* border-radius: 50%; */
}
.radial_circle5 {
  position: absolute;
  top: 0%;
  right: -8%;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    rgba(0, 56, 255, 0.23) 0%,
    rgba(0, 56, 255, 0.19) 0%,
    rgba(203, 0, 221, 0.07) 50%,
    rgba(203, 0, 221, 0) 75%
  );

  /* background: radial-gradient(rgba(0,56,255,0.3392) -10.4%,rgba(185,45,152,20%) 73.67%); */
  z-index: 1;
  /* border-radius: 50%; */
}
.radial_circle7 {
  position: absolute;
  top: -13%;
  right: -8%;
  width: 100%;
  height: 134%;
  background: radial-gradient(
    rgba(0, 56, 255, 0.23) 0%,
    rgba(0, 56, 255, 0.19) 0%,
    rgba(203, 0, 221, 0.07) 50%,
    rgba(203, 0, 221, 0) 75%
  );

  /* background: radial-gradient(rgba(0,56,255,0.3392) -10.4%,rgba(185,45,152,20%) 73.67%); */
  z-index: 1;
  /* border-radius: 50%; */
}
.radial_circle6 {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background: radial-gradient(
    farthest-side at top left,
    rgba(0, 56, 255, 0.23) 0%,
    rgba(0, 56, 255, 0.19) 0%,
    rgba(203, 0, 221, 0.07) 50%,
    rgba(203, 0, 221, 0) 75%
  );
  /* background: radial-gradient(rgba(0,56,255,0.3392) -10.4%,rgba(185,45,152,20%) 73.67%); */
  z-index: 1;
  /* border-radius: 50%; */
}
.radial_circle8 {
  position: absolute;
  top: 0%;
  left: -20%;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    rgba(0, 56, 255, 0.23) 0%,
    rgba(0, 56, 255, 0.19) 0%,
    rgba(203, 0, 221, 0.07) 50%,
    rgba(203, 0, 221, 0) 75%
  );

  /* background: radial-gradient(rgba(0,56,255,0.3392) -10.4%,rgba(185,45,152,20%) 73.67%); */
  z-index: 1;
  /* border-radius: 50%; */
}


@font-face {
  font-family: "Foco Light";
  font-style: normal;
  font-weight: normal;
  src: local("Foco Light"), url("fonts/FOCOLIGHT.woff") format("woff");
}