footer {
  padding: 125px;
  position: relative;
  overflow: hidden;
}
footer > div {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}
.contact_text {
  display: flex;
  flex-direction: column;
}
.contact_text h6 {
  font-size: 30px;
  margin-bottom: 27px;
  line-height: 27px;
  font-weight: 700;
}
.contact_text p {
  font-size: 20px;
  font-weight: 900;
  line-height: 27px;
}
.footer_para {
  font-size: 20px;
  line-height: 27px;
  font-weight: 900;
  margin-bottom: 20px;
}
.footer_para span {
  font-size: 24px;
}
.cookie_popup {
  position: relative;
  z-index: 1;
}
.cookie_popup p {
  font-size: 24px;
  font-weight: 900;
  line-height: 27px;
}
.cookie_popup p span i {
  font-size: 15px;
}
footer hr {
  width: 100%;
  height: 1px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 76px;
  margin-bottom: 36px;
}

.cursor-pointer {
  cursor: pointer;
}

.scroll-to-top-button {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 999;
}

.scroll-to-top-button button {
  background-color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}

.scroll-to-top-button.show {
  display: block;
}

.scroll-to-top-button.hide {
  display: none;
}

.scroll-to-top-button button:hover {
  background-color: gray;
}

.scroll-to-top-icon {
  width: 24px;
  height: 24px;
}
