.heading-ai {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  font-size: 50px;
}

@media screen and (max-width: 600px) {
  .heading-ai {
    font-size: 25px;
  }
}
