header {
  /* background: radial-gradient(42.38% 42.38% at 43.21% 41.99%,rgba(0,56,255,.256) 0,rgba(203,0,221,0) 57.04%); */
  width: 100%;
}

nav,
video {
  width: 100%;
}
nav {
  align-items: center;
  background-color: black;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 16px 64px;
  position: fixed;
  top: 0;
  z-index: 999;
}
.links {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.wtcolor > a {
  color: #fff;
  font-size: 24px;
  margin-left: 67px;
  font-family: "Abel", sans-serif;
  line-height: 31px;
  font-weight: 400;
}

#langselector {
  background-color: initial;
  border: none;
  color: #fff;
  font-size: inherit;
  padding: 5px;
  font-weight: 400;
  outline: none;
}
option {
  color: #000;
}
.header_text_box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  pointer-events: none;
  /* transition: height 1s ease-in; */
  padding: 0px 4rem;
}
.show-page {
  height: 34rem;
}
.hide-page {
  height: 100vh;
  overflow: hidden;
}
#scramble_large_text {
  text-transform: uppercase;
}
#scramble_small_text {
  margin-top: 20px;
}

@media (max-width: 1188px) {
  #scramble_small_text {
    height : 80px;
  }
}

@media (max-width: 900px) {
  .header_text_box {
    justify-content: center;
  }
}

@media (max-width: 600px) {
  #scramble_small_text {
    height : 144px;
  }
}

/* @media (max-width: 1350px) {
  #scramble_large_text {
    font-size: 50px;
  }
  #scramble_small_text {
    font-size: 36px;
  }
  .radial_container {
    height: 460px;
  }
}

@media (max-width: 950px) {
  #scramble_large_text {
    font-size: 30px;
  }
  #scramble_small_text {
    font-size: 21.5px;
  }
}



@media (max-width: 450px) {
  #scramble_large_text {
    font-size: 13.5px;
  }
  #scramble_small_text {
    font-size: 9px;
  }
} */
