.menu {
  display: none;
}

.navbar_menus {
  display: flex;
}

.languageSelector {
  z-index: 1;
  position: absolute;
  top: -4px;
  left: -9px;
  cursor: pointer;
}

@media (max-width: 1280px) {
  .navbar_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: rgb(24, 24, 24);
  }

  .navbar_menus {
    padding-top: 40px;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: start;
    align-items: start;
    width: 100%;
    height: 95vh;
  }

  .bg-black {
    display: none;
  }

  .languageSelector {
    position: relative;
  }

  .navbar_menus a {
    padding: 30px 15px;
    margin: 0;
  }
  .navbar_menus a:hover {
    background-color: white;
    color: black;
  }

  .navbar_mobile {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .menu button {
    margin-top: 0;
  }

  .block_menus {
    position: relative;
    animation-name: backToOriginal;
    animation-duration: 0.8s;
    display: flex;
  }

  @keyframes backToOriginal {
    0% {
      left: -400px;
    }
    100% {
      left: 0;
    }
  }

  .hide_menus {
    display: none;
    /* position: relative; */
    /* animation-name: backToOriginall;
    animation-duration: 0.5s;
    animation-fill-mode: forwards; */
  }

  /* @keyframes backToOriginall {
    0% {
      left: 0;
    }
    100% {
      left: -300px;
      display: none;
    }
  } */

  .menu {
    display: block;
  }
}

@media (max-width: 400px) {
  .navbar_menus a {
    padding: 30px 10px;
  }
  .block_menus {
    position: relative;
    animation-name: backToOriginal;
    animation-duration: 0.8s;
    display: flex;
  }

  @keyframes backToOriginal {
    0% {
      left: -400px;
    }
    100% {
      left: 0;
    }
  }

  .hide_menus {
    display: none;
    /* position: relative;
    animation-name: backToOriginall;
    animation-duration: 0.5s;
    animation-fill-mode: forwards; */
  }

  /* @keyframes backToOriginall {
    0% {
      left: 0;
    }
    100% {
      left: -300px;
      display: none;
    }
  } */
}
