#commitment-line {
  margin: 100px 0;
  padding: 64px 11.2rem;
  width: 100%;
  margin-bottom: 1.2rem;
  position: relative;
}
#main-section {
  background-color: #121212;
  z-index: 10;
  position: relative;
}
.hide-main-section {
  display: none;
}
.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}
.d-flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.z-2 {
  z-index: 2;
}
.bg-black {
  background-color: #000;
}
.animate-slide-up {
  transition: 1.6s linear;
  animation: slidein 1.6s;
  -webkit-transition: 1.6s linear;
  -moz-transition: 1.6s linear;
  -o-transition: 1.6s linear;
  -webkit-animation: slidein 1.6s;
  -moz-animation: slidein 1.6s;
  -o-animation: slidein 1.6s;
  animation-fill-mode: forwards;
}
.video-init {
  transform: translate(0, 20em);
  -webkit-transform: translate(0s, 20em);
}
.slidein {
  -webkit-animation-delay: 15s; /* Safari 4.0 - 8.0 */
  -moz-animation-delay: 15s;
  animation-delay: 15s;
}

@-moz-keyframes slidein {
  from {
    -moz-transform: translate(0s, 20em);
  }
  to {
    -moz-transform: translate(0em, 0);
  }
}

@-webkit-keyframes slidein {
  from {
    -webkit-transform: translate(0s, 20em);
  }
  to {
    -webkit-transform: translate(0em, 0);
  }
}

@keyframes slidein {
  from {
    transform: translate(0, 20em);
  }
  to {
    transform: translate(0em, 0);
  }
}
.commitment_flex {
  display: flex;
  flex-wrap: wrap;
  font-size: 15px;
}
.commitment_flex .commit_half {
  flex-basis: 50%;
  position: relative;
  text-align: left;
}
.commit_half h2 {
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  position: relative;
  text-transform: uppercase;
}
#commitment-heading img {
  background: transparent;
  /* bottom: -37px; */
  height: 110px;
  /* left: 48%; */
  position: absolute;
  width: 12.2rem;
  z-index: 0;
}
.commit_half p {
  font-size: 28px;
  font-weight: 300;
  line-height: 40px;
  width: 100%;
}
.commitment_flex .commit_full {
  flex-basis: 100%;
  margin-top: 44px;
  z-index: 10;
}
.commit_full p {
  font-size: 28px;
  font-weight: 300;
  line-height: 40px;
  /* letter-spacing: 0.5px; */
  margin-right: 15%;
  width: 100%;
}
.service {
  position: relative;
  padding-top: 100px;
}
#services-container {
  background: #080808;
  padding: 0 64px;
}
#services-container h2 {
  font-size: 65px;
  line-height: 80px;
  letter-spacing: 0.6px;
  margin-bottom: 50px;
  text-align: center;
  text-transform: uppercase;
}
.services_flex_1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 206px;
  width: 100%;
}
.services_flex_1 img {
  border-radius: 2px;
  width: 32%;
  box-shadow: 0 8px 24px hsla(0, 0%, 6%, 0.5);
}
.service_flex_2,
.project_evolv_section,
.project_eveolv_2,
.workshop_flex {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.project_eveolv_2 {
  padding-bottom: 113px;
  position: relative;
  height: 864px;
}
.video2 {
  height: 864px;
  background-color: white;
}
.service_flex_2 img {
  height: 869px;
  width: 50%;
}
.service_flex_2 img,
.service_flex_2 > div {
  flex-basis: 50%;
}
.devTools_text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 48px;
}
.devTools_text h3 {
  font-size: 65px;
  line-height: 80px;
  font-weight: 700;
  margin-bottom: 32px;
  text-transform: uppercase;
}
.devTools_text p {
  font-size: 2.2rem;
  line-height: 50px;
  font-weight: 400;
}
.project_evolv_section {
  height: 864px;
  position: relative;
}
.project_evolv_section > div {
  flex-basis: 50%;
}
.project_evolv_left h3 {
  font-size: 65px;
  line-height: 107px;
  font-weight: 700;
  text-transform: uppercase;
}
.project_evolv_left > div {
  padding-left: 64px;
}
.project_evolv_left h3,
.project_evolv_left p,
.project_evolv_left ul {
  margin-bottom: 16px;
}
.project_evolv_left p {
  font-size: 2.2rem;
  font-weight: 400;
}
.toolkitlist {
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  list-style: none;
}
.toolkitlist li {
  margin-bottom: 12px;
  font-size: 1.6rem;
  font-weight: 400;
  /* line-height: 60px; */
}
.para {
  font-size: 1.6rem;
  font-weight: 400;
}

.project_evolv2_right {
  font-size: 14px;
  font-weight: 300;
  padding-left: 64px;
  text-align: left;
}
.project_evolv2_right,
.project_evolv2_left {
  flex-basis: 50%;
}
.project_evolv2_right > div {
  letter-spacing: 0.5px;
  margin-bottom: 40px;
}
.visit_btn,
.consulting_btn,
.apply_btn,
.submit_btn {
  border-radius: 0;
  border-bottom-left-radius: 40px;
  border-top-right-radius: 40px;
  font-size: 14px;
  margin-top: 0;
  padding: 14px 28px;
  text-transform: uppercase;
}
.btn-gradient {
  background: radial-gradient(
    105.91% 1596.33% at 2.27% 10%,
    rgba(0, 56, 255, 0.7) 0,
    rgba(203, 0, 221, 0.5) 100%
  );
  margin-bottom: 26px;
}
#contract-n-consult {
  background: url(../img/paperwork.png);
  background-size: cover;
  position: relative;
  width: 100%;
}
#contract-n-consult > div {
  padding: 5rem 0rem;
  align-items: center;
  background: radial-gradient(
    100% 400% at 100% 0,
    #072aa6 0,
    rgba(163, 4, 166, 0.19) 100%
  );
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
#contract-n-consult h3 {
  font-size: 3.5rem;
  margin-bottom: 30px;
}
#contract-n-consult h4 {
  font-weight: 700;
  margin-bottom: 10px;
}
#contract-n-consult p {
  font-size: 28px;
  /* line-height: 1.6px; */
  margin-bottom: 30px;
  text-align: center;
  width: 52%;
  font-weight: 400;
}
.consulting_btn {
  background: #fff;
  color: #000;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 30px;
  padding: 1rem 3rem;
}
.workshop_events {
  padding: 64px;
  position: relative;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.workshop_events h3 {
  font-size: 4rem;
  margin-bottom: 40px;
  text-align: center;
  text-transform: uppercase;
}
.workshop_flex {
  background: rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
}
.workshop_flex > div {
  flex-basis: 50%;
}
.workshop_img {
  position: relative;
}
.workshop_img img {
  width: 100%;
}
.workshop_img::before {
  background: radial-gradient(
    141.11% 562% at 125.29% -25.23%,
    #072aa6 0,
    rgba(163, 4, 166, 0.19) 100%
  );
  content: "";
  height: 99.4%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}
.workshop_details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 3rem;
}
.workshop_details > div {
  margin-bottom: 61px;
}
.workshop_details h4 {
  font-size: 2rem;
  margin-bottom: 8px;
  font-weight: 700;
}
.workshop_details p {
  font-size: 1.5rem;
  margin-bottom: 4px;
}
.workshop_details .follow_text {
  font-size: 2rem;
  text-transform: uppercase;
  line-height: 27px;
  margin-top: 93px;
}
.employment {
  padding: 100px 6rem 161px 4rem;
  margin-top: 112px;
  position: relative;
}
.employment h2 {
  font-size: 65px;
  margin-bottom: 41px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
}
.employment_flex {
  display: flex;
  overflow: hidden;
  padding-right: 0;
  margin-right: 0;
  /* flex-wrap: wrap; */
}
.employment_card {
  background: #171717;
  border-radius: 5px;
  padding: 34px 1.1rem;
  width: 32%;
  /* width: 492px; */
  height: 880px;
  margin-right: 2%;
  z-index: 10;
  position: relative;
  flex-shrink: 0;
  transition: all 0.5s ease;
}
.employment_card h4 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 38px;
  text-transform: uppercase;
  line-height: 42px;
}
.employment_card > div {
  margin: 1.6rem 0;
}

.qualification_text p {
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 0.4px;
  line-height: 1.6;
  margin-top: 1.2rem;
}
.qualification_details span,
.job_type span,
.salary span {
  font-weight: 700;
  margin-right: 8px;
  font-size: 26px;
}

.job_type,
.salary {
  font-weight: 900;
  letter-spacing: 0.4px;
  font-size: 24px;
}
.empc-0 .apply_btn,
.empc-1 .apply_btn {
  margin-top: 50px;
}
.apply_btn {
  margin-bottom: 0;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 34px;
  padding: 24px 38px;
  position: absolute;
  bottom: 1.2rem;
  margin-top: 1.2rem;
}
.visit_btn {
  margin-bottom: 40px;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 26px;
}
#investor {
  background-color: #171717;
  padding-top: 3rem;
}
.investors {
  color: white;
  font-size: 1.1rem;
  letter-spacing: 0.2px;
  line-height: 1.2;
  padding: 75px 64px;
  text-align: center;
}
.investors h2 {
  font-size: 4.1rem;
  font-weight: 700;
  line-height: 93px;
  text-transform: uppercase;
}
.investors p {
  font-size: 40px;
  transform: scale(0.8, 1);
  font-weight: 900;
  line-height: 55px;
}
.contact_form {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 0;
  justify-content: space-between;
  margin: 40px auto 0;
  width: 70%;
}
.contact_form input[type="text"] {
  width: 45%;
  font-size: 1.4rem;
  color: black;
}
.contact_form input,
.contact_form textarea {
  font-size: 1.4rem;
  background: #fffdea;
  border: 1px solid #121212;
  border-radius: 2px;
  outline: none;
  padding: 16px;
  font-family: "Foco Light", "Helvetica", sans-serif !important;
}
.contact_form input[type="email"],
.contact_form textarea {
  width: 100%;
  font-size: 1.4rem;
  color: black;
}
.submit_btn {
  background: radial-gradient(
    105.91% 1596.33% at 2.27% 10%,
    rgba(0, 56, 255, 0.7) 0,
    rgba(203, 0, 221, 0.5) 100%
  );
  font-weight: 700;
  margin: 0 auto;
  line-height: 34px;
  padding: 14px 68px;
  font-size: 26px;
}
.slide_arrow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 0rem;
  width: 96%;
}
.slide_arrow span {
  margin: 0 8px;
}
.slide_arrow span i {
  font-size: 1.2rem;
}
.video1 {
  height: 864px;
  background-color: black;
}

@media only screen and (max-width: 1678px) {
  .employment_card h4 {
    font-size: 28px;
  }
  .qualification_details span,
  .job_type span,
  .salary span {
    font-size: 22px;
  }
  .qualification_text p,
  .job_type,
  .salary {
    font-size: 1.2rem;
  }
  .employment_card {
    /* width: 413px; */
  }
}
@media only screen and (max-width: 1441px) {
  .employment_card {
    /* width: 389px; */
  }
  .employment {
    padding: 100px 1rem 161px 1rem;
  }
}

.before_services_section {
  background: url("../img/techbackgroundd.jpg") no-repeat center/cover;
  height: 400px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.before_services_section h2 {
  color: #ffffff;
  opacity: 1;
  z-index: 1;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Adding shadow to h2 text */
}

.before_services_section h3 {
  font-size: 2rem;
  color: #ffffff;
  margin-top: 10px;
  opacity: 1;
  z-index: 1;
}

.before_services_section p {
  font-size: 1.5rem;
  color: #ffffff;
  margin-top: 10px;
  opacity: 1;
  z-index: 1;
}

.before_services_section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  pointer-events: none;
}

.developer_list li {
  padding: 5px 0rem;
}

.alert {
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: space-around;
  padding: 30px 0rem;
  background-color: #48bb78; /* Replace with your desired green color */
  color: #fff;
}

.alert-content {
  display: flex;
  align-items: center;
}

.icon {
  height: 1.75rem; /* Equivalent to 7px in Tailwind */
  width: 1.75rem; /* Equivalent to 7px in Tailwind */
  margin-right: 1.5rem; /* Equivalent to 6px in Tailwind */
}

.close-button {
  color: #f7fafc; /* Equivalent to text-green-100 in Tailwind */
  cursor: pointer;
  transition: color 0.2s ease;
  background-color: transparent;
}

.close-button:hover {
  color: #fff; /* Equivalent to hover:text-white in Tailwind */
}

.close-icon {
  height: 1.9rem; /* Equivalent to 6px in Tailwind */
  width: 1.9rem; /* Equivalent to 6px in Tailwind */
}

.employment h2 {
  font-size: 44px;
}
.logo_emply {
  position: absolute;
  left: 128px;
  top: 30px;
  cursor: pointer;
  width: 130px;
}

.hr_emply {
  width: 150px;
  border-top: 1px solid blue;
  margin-top: 0;
}

#servicesHeading{
  text-align: center;
  padding: 0;
  margin: -2rem 0 2rem 0;
}

@media (max-width: 700px) {
  .employment h2 {
    font-size: 16px;
  }
  .devTools_text h3 {
    font-size: 26px;
    line-height: 28px;
  }
  .logo_emply {
    left: 38px;
    top: 30px;
    width: 50px;
  }
  .hr_emply {
    width: 50px;
  }
}

@media (max-width: 1380px) {
  .radial_circle1 {
    display: none;
  }
  .commitment_flex {
    background: black;
  }
  #commitment-line {
    margin: 0;
    padding: 60px 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #commitment-heading img {
    display: none;
  }
  .commit_half h1 {
    text-align: center;
    padding-right: 1.2rem;
    padding-left: 1.2rem;
    padding-bottom: 1.2rem;
  }
  .commit_half p {
    font-size: 1.2rem;
    min-width: 300px;
    padding: 0rem 1.2rem;
    text-align: left;
  }
  .commit_full p {
    font-size: 1.2rem;
    min-width: 300px;
    padding: 0rem 1.2rem;
    text-align: left;
  }

  .services_flex_1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
  }
  .services_flex_1 img {
    display: none;
  }
  #services-container h2 {
    font-size: 40px;
    margin: 0;
  }
  .service {
    background-color: black;
    padding: 0;
  }
  .service_flex_2 {
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;
  }
  .middle {
    flex-direction: column-reverse;
  }
  .service_flex_2 img {
    width: 100%;
    padding: 0;
    margin: 0;
    height: 100%;
  }
  .devTools_text {
    margin: 0;
    background-color: black;
    color: white;
  }

  .devTools_text p {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
  .devTools_text ul li {
    font-size: 1.5rem;
  }

  #contract-n-consult h3 {
    font-size: 2.2rem;
    padding: 0rem 1.2rem;
    text-align: center;
  }
  #contract-n-consult p {
    font-size: 1.2rem;
    padding: 0rem 1.2rem;
    width: 100%;
    text-align: center;
  }
  #contract-n-consult h4 {
    font-size: 1.2rem;
    padding: 0rem 1.2rem;
    width: 100%;
    text-align: center;
  }
  #contract-n-consult button {
    font-size: 1.1rem;
  }
  .workshop_events {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    background-color: black;
    padding: 50px 0rem;
    margin: 0;
  }
  .workshop_events img {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
  .workshop_flex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .workshop_details {
    padding: 1.2rem 1.2rem;
  }
  .employment {
    background-color: black;
    color: white;
    margin: -10px;
    padding: 1.2rem 0rem;
  }

  .employment_card h4 {
    font-size: 1.6rem;
    line-height: 2.2rem;
    padding: 5px;
  }
  .employment_card p {
    font-size: 1.1rem;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 10px;
  }
  .employment_card {
    width: 300px;
    height: 101.2rem;
  }

  .employment_flex {
    justify-content: space-between;
    overflow: hidden;
    overflow-x: auto;
    width: 100%;
    display: flex;
    scroll-behavior: smooth;
    scroll-snap-type: x proximity;
    -webkit-overflow-scrolling: touch;
  }
  .employment_card button {
    font-size: 15px;
    margin-left: 30px;
  }
  .job_type {
    padding: 0rem 5px;
  }
  .salary {
    padding-bottom: 10px;
    padding-left: 5px;
  }
  .contact_form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .contact_form input,
  .contact_form textarea {
    width: 100%;
    margin-bottom: 15px;
  }
  .investors {
    margin: 0rem 0rem;
    padding: 30px 0rem;
  }
  .contact_form input[type="text"] {
    width: 100%;
  }
  .radial_circle6 {
    display: none;
  }
  .radial_circle7 {
    display: none;
  }
  footer {
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  footer .container_footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  footer .container_footer img {
    padding-bottom: 30px;
  }
  footer p {
    text-align: center;
    width: 300px;
  }
  footer .cookie-1 {
    height: 600px;
    text-align: center;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  footer .cookie-1 p {
    font-size: 15px;
    text-align: center;
  }
  .slide_arrow {
    display: none;
  }
  .read_more_btn {
    padding: 6px 50px;
    font-size: 14px;
  }
}


@media only screen and (max-width: 600px) {
  .before_services_section p {
    font-size: 1.3rem;
  }

  .investors h2 {
    font-weight: 100;
}

.submit_btn {
  font-size: 15px;
}
}
