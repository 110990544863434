.modern-design-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 30px;
  padding-top: 30px;
  flex-wrap: wrap;
}

.modern-design-container-left {
  background-color: rgba(29, 24, 59, 0.507);
  padding: 60px 80px;
  border-radius: 40px;
  position: relative;
  color: blue;
  font-size: 30px;
  text-align: center;
  margin-bottom: 30px;
}

.left-element {
  position: absolute;
  background-color: blue;
  border-radius: 50%;
  left: -10px;
  bottom: -10px;
  padding: 30px;
  box-shadow: 0 0 50px rgba(0, 0, 255, 0.5);
}

.right-element {
  position: absolute;
  background-color: blue;
  border-radius: 50%;
  padding: 20px;
  top: -10px;
  right: -10px;
  box-shadow: 0 0 50px rgba(0, 0, 255, 0.5);
}

.modern-design-container-right {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  justify-content: center;
  align-items: flex-start;
}
