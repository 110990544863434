.cookie-1 {
  width: 100%;
  background-color: #fffdea;
  height: 325px;
  display: flex;
  padding: 63px 49px 45px 49px;
  flex-direction: column;
}
.cookie-1 h6 {
  color: black;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  margin-bottom: 20px;
}
.cookie-1 p {
  color: black;
  font-size: 20px;
  font-weight: 900;
  line-height: 26px;
  margin-bottom: 20px;
}
.cookie-1 p span {
  font-size: inherit;
  color: black;
  font-weight: 900;
  line-height: 26px;

  border-bottom: 1px solid black;
}
.cookie-1 a {
  text-transform: uppercase;
  color: black;
  font-size: 22px;
  line-height: 29px;
  font-weight: 700;
  text-decoration: none;
  border-bottom: 1px solid black;
  text-align: center;
  position: relative;
  z-index: 1;
}
.cookie-1 div {
  width: 100%;
  text-align: center;
}
.cookie-1 div {
  font-size: 25px;
}
.cross_cookie {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: black;
  position: relative;
  z-index: 1;
}
