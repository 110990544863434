.container {
  width: 90%;
  margin: 0 auto;
  color: white;
}

h1 {
  padding-top: 20px;
  font-size: 25px;
  padding-bottom: 20px;
}
h2 {
  margin-top: 20px;
  font-size: 16px;
}

.input-group {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.input-group2 {
  margin-bottom: 15px;
  display: flex;
  max-width: 550px;
  justify-content: space-between;
}

.label {
  display: inline-block;
  width: 150px;
  font-weight: bold;
}

input,
textarea {
  width: 380px;
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 4px;
  color: white;
  background: linear-gradient(
    90deg,
    rgba(58, 107, 180, 0.282) 0%,
    rgba(255, 255, 255, 0) 101.71%
  );
}

#additional-info {
  width: 95%;
}
.tcontainer {
  margin: 0;
  padding: 20px 0px;
}

@media (max-width: 600px) {
  .input-group {
    display: flex;
    flex-direction: column;
  }
  .input-group2 {
    display: flex;
    flex-direction: column;
  }
}
