.t-container {
  width: 90%;
  margin: 0 auto;
  color: white;
  padding: 20px 0px;
}

.t-ultimate-container {
  background: radial-gradient(
    farthest-side at top left,
    rgba(0, 56, 255, 0.23) 0%,
    rgba(0, 56, 255, 0.19) 30%,
    rgba(203, 0, 221, 0.07) 60%,
    rgba(0, 56, 255, 0.15) 95%
  );
}

.t-description {
  font-size: 20px;
  color: rgb(235, 235, 235);
  font-family: sans-serif;
}

.f-text {
  color: #faff00;
  text-align: center;
  font-size: 25px;
  font-family: sans-serif;
  padding: 30px 0px;
}
.tg-container p {
  font-size: 22px;
  font-family: sans-serif;
  text-align: center;
  padding: 20px;
}

.tg-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0;
}

.tg-container img {
  width: 550px;
  padding: 20px;
}
.t-description2 {
  font-size: 22px;
  font-family: sans-serif;
  color: yellow;
}
.t-text {
  font-size: 22px;
  font-family: sans-serif;
  text-align: center;
  padding: 30px 0;
}

.logo_testing {
  position: absolute;
  left: 0;
  width: 100px;
}

.t_head1 {
  font-weight: bold;
  padding-bottom: 5;
  margin-bottom: 0;
  margin-top: 70px;
  color: black;
  font-size: 36px;
  text-shadow: 0 0 10px rgba(255, 255, 255, 1);
}

.t_head2 {
  font-size: 15.5px;
  margin-bottom: 40px;
  color: white;
  opacity: 0.8;
  margin-top: 5px;
}

.submit_btn {
  background: radial-gradient(
    105.91% 1596.33% at 2.27% 10%,
    rgba(0, 56, 255, 0.7) 0,
    rgba(203, 0, 221, 0.5) 100%
  );
  font-weight: 700;
  font-size: 20px;
  margin: 0 auto;
  display: block;
}

@media (max-width: 600px) {
  .t_head1 {
    font-size: 19px;
  }
  .t_head2 {
    font-size: 8px;
  }
  .tg-container img {
    max-width: 350px;
    padding: 20px 0px;
  }
  .submit_btn {
    font-size: 15px;
    padding: 10px 30px;
  }
}
