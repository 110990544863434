/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal Content */
.modal-content {
  opacity: 1;
  padding: 30px;
  z-index: 999;
  background: radial-gradient(
    farthest-side at top left,
    rgba(0, 56, 255, 0.23) 0%,
    rgba(0, 56, 255, 0.19) 50%,
    rgba(203, 0, 221, 0.07) 95%
  );
  width: 60%;
  max-width: 400px;
  height: 400px;
  text-align: center;
  position: relative;
  animation-name: backToOriginalModal;
  animation-duration: 0.4s;
}

@keyframes backToOriginalModal {
  0% {
    margin-top: -500px;
  }

  100% {
    margin-top: 0;
  }
}

/* Close Button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

/* Modal Logo */
.modal-logo {
  width: 100px; /* Adjust this size as needed */
  height: 100px; /* Adjust this size as needed */
  margin-bottom: 20px;
}
